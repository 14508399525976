import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Typography, Box } from "@mui/material";

type SubContainerProps = {
    first?: boolean;
  };
  
  type descriptionProps = {
    second?: boolean;
  };
  
  type titleProps = {
    second?: boolean;
  };
  
  type imageLinkProps = {
    second?: boolean;
  };


export const Container = styled(Box)(({ theme }) => ({
    width: "80%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    // justifyContent: "center",
    flexDirection: "column",
    margin: "3.5rem auto 0px auto",
    [theme.breakpoints.up(1200)]: {
      flexDirection: "column",
      marginTop: "35px",
      width: "80%",
    },
  }));
  
  export const SubContainer = styled(Box)<SubContainerProps>(
    ({ theme, first }) => ({
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      [theme.breakpoints.up(0)]: {
        borderBottom: first && "3px solid #1A72B8",
        padding: "30px 0px 30px 0px",
      },
      [theme.breakpoints.up(570)]: {
        flexDirection: "row",
      },
      [theme.breakpoints.up(1200)]: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap:"nowrap",
        padding: "0px 0px 20px 0px",
      },
    })
  );
  
  export const ContainerLogo = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(1200)]: {
      flexDirection: "column",
      width: "40%",
      justifyContent: "flex-start",
    },
  }));
  
  export const ImageArcor = styled("img")(({ theme }) => ({
    width: "145px",
    height: "90px",
    [theme.breakpoints.up("md")]: {
      width: "165px",
      height: "100px",
    },
  }));
  
  export const BodyContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(1200)]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginLeft: "100px",
    },
  }));
  
  export const TitleContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0px",
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.up(1200)]: {
      justifyContent: "center",
      padding: 0,
      margin: "30px 0px 0px 0px",
    },
  }));
  
  export const Title = styled(Typography)<titleProps>(({ theme, second }) => ({
    fontSize: "2.8rem",
    fontFamily: "Telolet",
    fontWeight: "400",
    lineHeight: "2.8rem",
    color: "#3366CC",
    textAlign: "center",
    [theme.breakpoints.up(1200)]: {
      textAlign: "center",
      width: second ? "100%" : "70%",
      fontSize: "2.5rem",
    },
  }));
  
  export const DescriptionContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    marginBottom: "20px",
    marginTop: "20px",
    [theme.breakpoints.up(1200)]: {
      flexDirection: "column",
      gap: "40px",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  }));
  
  export const Description = styled(Typography)<descriptionProps>(
    ({ theme, second }) => ({
      fontFamily: "Raleway",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "149%",
      textAlign: "center",
      [theme.breakpoints.up(1200)]: {
        width: "60%",
      },
    })
  );

  export const StyledAnchor = styled("a")(({ theme }) => ({
    textDecoration: "none",  // Elimina subrayado
    color: "#3366CC",        // Color del enlace
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: "500",
  
    [theme.breakpoints.up(1200)]: {
      // Estilos adicionales para pantallas grandes
    },
  }));
  
  export const ImageLinkContainer = styled(Box)<imageLinkProps>(
    ({ theme, second }) => ({
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "20px",
      [theme.breakpoints.up(1200)]: {
        flexDirection: second ? "column" : "column-reverse",
        marginTop: second && "30px",
      },
    })
  );
  
  export const LinkContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }));
  
  export const Text = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontSize: "21px",
    fontWeight: "700",
    lineHeight: "27px",
    textAlign: "center",
    color: "#3366CC",
    textTransform: "uppercase",
  }));
  
  export const LinkTo = styled(Link)(() => ({
    fontFamily: "Raleway",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "27px",
    textAlign: "center",
    color: "#3366CC",
  }));
  
  export const ImageRight = styled("img")(({ theme }) => ({
    width: "248px",
    height: "129px",
    [theme.breakpoints.up(1200)]: {
      margin: "30px 0px 20px 0px",
      position: "absolute",
      right: '5%'
    },
  }));
  export const ImageLeft = styled("img")(({ theme }) => ({
    width: "76px",
    height: "48px",
    [theme.breakpoints.up(1200)]: {
      margin: "30px 0px 20px 0px",
      position: "absolute",
      left: '10%'
    },
  }));