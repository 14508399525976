import { HeaderLayout } from '../layouts/HeaderLayout';
import { Materiales } from '../components/Materiales';
import { ContainerPage } from './styles/materialesPageStyles';

import ReactGA from "react-ga4";

export const MaterialesPage = () => {

  return (
    <ContainerPage>
      <HeaderLayout>
        <Materiales />
      </HeaderLayout>
    </ContainerPage>
  )
}