import { Box, useMediaQuery } from '@mui/material';
import { BodyContainer,
    Container,
    SubContainer,
    TitleContainer,
    Title,
    ImageLinkContainer,
    Text,
    PDFTitle,
    LinkContainer,
    LinkTo,
    StyledAnchor,
    Image, } from './styles';

type ItemProps = {
    image: string;
    title: string;
    pdfLink: string;
}

export const Item: React.FC<ItemProps> = ({ image, title, pdfLink}) => {
    const isMobile = useMediaQuery("(max-width:1200px)");
    const isMobileSmall = useMediaQuery("(max-width:900px)");

    return (
        <Container>
            <StyledAnchor href={pdfLink} target='_blank'>
                <Image src={image} alt={title} />
                <TitleContainer>
                    <PDFTitle>{title}</PDFTitle>
                </TitleContainer>
            </StyledAnchor>
        </Container>
    )
}