import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ResetPasswordPage } from "../pages/ResetPasswordPage"
import { ForgotPasswordPage } from "../pages/ForgotPasswordPage"
import { HomePage } from "../pages/HomePage"
import { LoginPage } from "../pages/LoginPage"
import { RegisterPage } from "../pages/RegisterPage"
import { ReservaTourPage } from "../pages/ReservaTourPage"
import { ReservaExitosaPage } from "../pages/ReservaExitosaPage"
import { PreRecorridoRapidoPage } from "../pages/PreRecorridoRapidoPage"
import { QuienesSomosPage } from "../pages/QuienesSomosPage"
import { ProfilePage } from "../pages/ProfilePage"
import { ContactoPage } from "../pages/ContactoPage"
import { TermsPage } from "../pages/TermsPage"
import { EnterTourPage } from "../pages/EnterTourPage"
import { ReserveConfirmPage } from "../pages/ReserveConfirmPage"
import { EncuestaPage } from "../pages/EncuestaPage"
import { UnsuscribeAlertsPage } from "../pages/UnsuscribeAlertsPage"
import { MaterialesPage } from "../pages/MaterialesPage"

import { PublicRoutes } from "./PublicRoutes"
import { PrivateRoutes } from "./PrivateRoutes"
import { MyTourPages } from "../pages/MyToursPage"
import { SuccessRegisterPage } from "../pages/SucessRegisterPage"
import { ComingSoonPage } from "../pages/ComingSoonPage"
import { SalaDeEspera } from "../pages/SalaDeEspera"
import { ConfirmTourPage } from "../pages/ConfirmTour"
import { CancelTourPage } from "../pages/CancelTour"

import { useAppSelector } from "../redux/hooks"
import { useEffect } from "react"
import { axiosInstance } from "../services/axiosInstance"
import { ScrollToTop } from "../helpers/scrollToTop"

export const AppRouter = () => {
  const token = useAppSelector((state) => state.auth.token)

  useEffect(() => {
    if (token) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`
    } else {
      delete axiosInstance.defaults.headers.common["Authorization"]
    }
  }, [token])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="/auth/login" element={<LoginPage />} />
            <Route path="/auth/register" element={<RegisterPage />} />
            <Route
              path="/auth/forgot-password"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="/api/reset-password/:token/:email"
              element={<ResetPasswordPage />}
            />
            <Route
              path="/api/email/verifyAccount/:token"
              element={<SuccessRegisterPage />}
            />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/reserva-tour" element={<ReservaTourPage />} /> */
            <Route
              path="/reserva/reserva-exitosa"
              element={<ReservaExitosaPage />}
            />
            <Route path="/user/profile" element={<ProfilePage />} />
            <Route path="/confirm-reserve" element={<ReserveConfirmPage />} />
            <Route path="/mis-tours" element={<MyTourPages />} />
          </Route>
          <Route path="/comingsoon" element={<ComingSoonPage />} />
          <Route path="/recorridorapido" element={<PreRecorridoRapidoPage />} />
          <Route path="/contacto" element={<ContactoPage />} />
          <Route path="/materiales" element={<MaterialesPage />} />
          <Route path="/quienes-somos" element={<QuienesSomosPage />} />
          <Route path="/terminos-condiciones" element={<TermsPage />} />
          <Route path="/ingresar-tour" element={<EnterTourPage />} />
          <Route path="/encuesta/:id" element={<EncuestaPage />} />
          <Route path="/sala-de-espera" element={<SalaDeEspera />} />
          <Route path="/confirm-tour" element={<ConfirmTourPage />} />
          <Route path="/cancel-tour" element={<CancelTourPage />} />
          <Route path="/unsuscribe-alerts" element={<UnsuscribeAlertsPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}
