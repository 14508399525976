import { Box, useMediaQuery } from '@mui/material';
import { data } from "./data";
import { Item } from './Item';
import dancingGirl from '../../assets/images/Materials/girl.png'
import bonoBom from '../../assets/images/Materials/bonobom.png'
import { BodyContainer,
    Container,
    SubContainer,
    ContainerLogo,
    ImageArcor,
    TitleContainer,
    Title,
    DescriptionContainer,
    Description,
    ImageLinkContainer,
    Text,
    LinkContainer,
    LinkTo,
    StyledAnchor,
    ImageRight,
    ImageLeft } from './styles';


export const Materiales = () => {

    const isMobile = useMediaQuery("(max-width:1200px)");
    const isMobileSmall = useMediaQuery("(max-width:900px)");

    return (
        <Container>
            <TitleContainer>
                <Title>Un viaje por el mundo de los alimentos desde el aula</Title>
            </TitleContainer>
            <DescriptionContainer>
                <ImageLeft src={bonoBom} />
                <Description>Descargá los contenidos y continuen trabajando con Universo Arcor en la escuela.</Description>
                <Description>Estos materiales fueron desarrollados por <StyledAnchor href="https://www.foro21.com.ar/" target='_blank'>Foro 21</StyledAnchor> y dentro de cada uno encontrarás las áreas curriculares a las que aplican.</Description>
                <ImageRight src={dancingGirl} />
            </DescriptionContainer>
            <TitleContainer>
                <Text>Historia de los alimentos</Text>
            </TitleContainer>
            <SubContainer>
                {data.history.map((element, index) => (
                    <Item
                    key={index}
                    image={element.image}
                    title={element.title}
                    pdfLink={element.pdfLink}
                />
                ))}
                
            </SubContainer>
            <TitleContainer>
                <Text>Procesos productivos</Text>
            </TitleContainer>
            <SubContainer>
                {data.process.map((element, index) => (
                    <Item
                    key={index}
                    image={element.image}
                    title={element.title}
                    pdfLink={element.pdfLink}
                />
                ))}
                
            </SubContainer>
        </Container>
    )
}